*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: Source Sans Pro, ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden]:where(:not([hidden="until-found"])) {
  display: none;
}

@font-face {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("source-sans-pro-400.211c70b7.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("source-sans-pro-600.c0d946ee.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("source-sans-pro-700.0ed89466.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Capito;
  font-weight: 300 800;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  src: url("Capito04VAR.5e448045.woff2") format("woff2");
}

@font-face {
  font-family: Capito;
  font-weight: 300 800;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
  src: url("Capito04VAR-Italic.4a8f5023.woff2") format("woff2");
}

em {
  font-family: Capito, ui-serif, Georgia, Cambria, Times New Roman, Times, serif;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }

  5% {
    opacity: 1;
  }

  25% {
    opacity: 1;
  }

  30% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translate(-50%, -100%);
  }

  60% {
    transform: translate(-50%, 12%);
  }

  100% {
    opacity: 1;
    transform: translate(-50%);
  }
}

[data-elm-hot] > :last-child {
  opacity: .2;
}

.range[type="range"] {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  background: none;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.range[type="range"]:focus {
  outline: none;
}

.range[type="range"]::-ms-track {
  cursor: pointer;
  color: #0000;
  background: none;
  border-color: #0000;
  width: 100%;
}

.range[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  cursor: pointer;
  background: #fff;
  border-radius: 100%;
  width: 32px;
  height: 32px;
  margin-top: -14px;
  box-shadow: 0 2px 4px #0000001a;
}

.range[type="range"]:focus-visible::-webkit-slider-thumb {
  outline: 3px solid #b27700;
}

.range[type="range"]::-moz-range-thumb {
  cursor: pointer;
  background: #fff;
  border: none;
  border-radius: 100%;
  width: 32px;
  height: 32px;
  box-shadow: 0 2px 4px #0000001a;
}

.range[type="range"]::-ms-thumb {
  cursor: pointer;
  background: #fff;
  border-radius: 100%;
  width: 32px;
  height: 32px;
  box-shadow: 0 2px 4px #0000001a;
}

.range[type="range"]::-webkit-slider-runnable-track {
  cursor: pointer;
  background: #ffeba3;
  border-radius: 4px;
  width: 100%;
  height: 4px;
}

.range[type="range"]::-moz-range-track {
  cursor: pointer;
  background: #ffeba3;
  border-radius: 4px;
  width: 100%;
  height: 4px;
}

.range[type="range"]::-ms-track {
  cursor: pointer;
  color: #0000;
  background: none;
  border-width: 4px 0;
  border-color: #0000;
  width: 100%;
  height: 4px;
}

.range[type="range"]::-ms-fill-lower {
  background: #ffeba3;
  border-radius: 4px;
}

.range[type="range"]:focus::-ms-fill-lower {
  background: #ffeba3;
}

.range[type="range"]::-ms-fill-upper {
  background: #ffeba3;
  border-radius: 4px;
}

.range[type="range"]:focus::-ms-fill-upper {
  background: #ffeba3;
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-0 {
  inset: 0;
}

.inset-x-0 {
  left: 0;
  right: 0;
}

.inset-x-2 {
  left: .5rem;
  right: .5rem;
}

.-left-2\.5 {
  left: -.625rem;
}

.-top-2\.5 {
  top: -.625rem;
}

.-top-6 {
  top: -1.5rem;
}

.bottom-0 {
  bottom: 0;
}

.bottom-6 {
  bottom: 1.5rem;
}

.bottom-full {
  bottom: 100%;
}

.left-0 {
  left: 0;
}

.left-4 {
  left: 1rem;
}

.right-0 {
  right: 0;
}

.right-1 {
  right: .25rem;
}

.right-3 {
  right: .75rem;
}

.right-6 {
  right: 1.5rem;
}

.top-1 {
  top: .25rem;
}

.top-3 {
  top: .75rem;
}

.top-4 {
  top: 1rem;
}

.top-8 {
  top: 2rem;
}

.top-full {
  top: 100%;
}

.z-10 {
  z-index: 10;
}

.z-40 {
  z-index: 40;
}

.z-50 {
  z-index: 50;
}

.order-1 {
  order: 1;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.col-start-1 {
  grid-column-start: 1;
}

.col-start-2 {
  grid-column-start: 2;
}

.row-span-2 {
  grid-row: span 2 / span 2;
}

.row-span-3 {
  grid-row: span 3 / span 3;
}

.row-span-5 {
  grid-row: span 5 / span 5;
}

.row-span-6 {
  grid-row: span 6 / span 6;
}

.row-start-1 {
  grid-row-start: 1;
}

.row-start-2 {
  grid-row-start: 2;
}

.row-start-3 {
  grid-row-start: 3;
}

.row-start-4 {
  grid-row-start: 4;
}

.row-start-5 {
  grid-row-start: 5;
}

.row-start-6 {
  grid-row-start: 6;
}

.m-4 {
  margin: 1rem;
}

.-my-2 {
  margin-top: -.5rem;
  margin-bottom: -.5rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.-ml-2 {
  margin-left: -.5rem;
}

.-mr-2 {
  margin-right: -.5rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-14 {
  margin-bottom: 3.5rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-auto {
  margin-left: auto;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-1\.5 {
  margin-top: .375rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-auto {
  margin-top: auto;
}

.line-clamp-2 {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.h-1\/2 {
  height: 50%;
}

.h-10 {
  height: 2.5rem;
}

.h-12 {
  height: 3rem;
}

.h-14 {
  height: 3.5rem;
}

.h-3 {
  height: .75rem;
}

.h-4 {
  height: 1rem;
}

.h-5 {
  height: 1.25rem;
}

.h-5\/6 {
  height: 83.3333%;
}

.h-6 {
  height: 1.5rem;
}

.h-8 {
  height: 2rem;
}

.h-9 {
  height: 2.25rem;
}

.h-\[104px\] {
  height: 104px;
}

.h-\[10px\] {
  height: 10px;
}

.h-\[560px\] {
  height: 560px;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.max-h-3 {
  max-height: .75rem;
}

.max-h-\[90\%\] {
  max-height: 90%;
}

.min-h-screen {
  min-height: 100vh;
}

.w-0\.5 {
  width: .125rem;
}

.w-10 {
  width: 2.5rem;
}

.w-12 {
  width: 3rem;
}

.w-14 {
  width: 3.5rem;
}

.w-20 {
  width: 5rem;
}

.w-3 {
  width: .75rem;
}

.w-4 {
  width: 1rem;
}

.w-40 {
  width: 10rem;
}

.w-5 {
  width: 1.25rem;
}

.w-5\/6 {
  width: 83.3333%;
}

.w-6 {
  width: 1.5rem;
}

.w-8 {
  width: 2rem;
}

.w-\[10px\] {
  width: 10px;
}

.w-full {
  width: 100%;
}

.max-w-3xl {
  max-width: 48rem;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-md {
  max-width: 28rem;
}

.max-w-prose {
  max-width: 65ch;
}

.max-w-screen-lg {
  max-width: 1024px;
}

.max-w-screen-xl {
  max-width: 1280px;
}

.max-w-xl {
  max-width: 36rem;
}

.max-w-xs {
  max-width: 20rem;
}

.shrink-0 {
  flex-shrink: 0;
}

.grow {
  flex-grow: 1;
}

.-translate-x-1\.5 {
  --tw-translate-x: -.375rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-0\.5 {
  --tw-translate-y: -.125rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-1 {
  --tw-translate-y: .25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-px {
  --tw-translate-y: 1px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-90 {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-scale-x-100 {
  --tw-scale-x: -1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-pointer {
  cursor: pointer;
}

.appearance-none {
  appearance: none;
}

.grid-flow-col {
  grid-auto-flow: column;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-1 {
  gap: .25rem;
}

.gap-1\.5 {
  gap: .375rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-20 {
  gap: 5rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-x-2 {
  column-gap: .5rem;
}

.gap-x-4 {
  column-gap: 1rem;
}

.gap-y-2 {
  row-gap: .5rem;
}

.gap-y-4 {
  row-gap: 1rem;
}

.gap-y-6 {
  row-gap: 1.5rem;
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.75rem * var(--tw-space-y-reverse));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.place-self-center {
  place-self: center;
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.justify-self-start {
  justify-self: start;
}

.justify-self-end {
  justify-self: end;
}

.justify-self-center {
  justify-self: center;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.whitespace-pre-line {
  white-space: pre-line;
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
}

.break-words {
  overflow-wrap: break-word;
}

.rounded {
  border-radius: .25rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded-b-xl {
  border-bottom-right-radius: .75rem;
  border-bottom-left-radius: .75rem;
}

.rounded-r-full {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

.border-0 {
  border-width: 0;
}

.border-2 {
  border-width: 2px;
}

.border-dashed {
  border-style: dashed;
}

.border-black {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity, 1));
}

.border-primary-300 {
  --tw-border-opacity: 1;
  border-color: hsl(47 100% 65% / var(--tw-border-opacity, 1));
}

.border-primary-600 {
  --tw-border-opacity: 1;
  border-color: hsl(40 100% 35% / var(--tw-border-opacity, 1));
}

.border-yellow-500 {
  --tw-border-opacity: 1;
  border-color: rgb(234 179 8 / var(--tw-border-opacity, 1));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity, 1));
}

.bg-black\/80 {
  background-color: #000c;
}

.bg-grey-100 {
  --tw-bg-opacity: 1;
  background-color: hsl(240 10% 96% / var(--tw-bg-opacity, 1));
}

.bg-grey-300 {
  --tw-bg-opacity: 1;
  background-color: hsl(220 3% 77% / var(--tw-bg-opacity, 1));
}

.bg-grey-50 {
  --tw-bg-opacity: 1;
  background-color: hsl(240 29% 99% / var(--tw-bg-opacity, 1));
}

.bg-primary-100 {
  --tw-bg-opacity: 1;
  background-color: hsl(47 100% 91% / var(--tw-bg-opacity, 1));
}

.bg-primary-200 {
  --tw-bg-opacity: 1;
  background-color: hsl(47 100% 82% / var(--tw-bg-opacity, 1));
}

.bg-primary-300 {
  --tw-bg-opacity: 1;
  background-color: hsl(47 100% 65% / var(--tw-bg-opacity, 1));
}

.bg-primary-300\/80 {
  background-color: #ffd84ccc;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.fill-black {
  fill: #000;
}

.fill-current {
  fill: currentColor;
}

.fill-gray-400 {
  fill: #9ca3af;
}

.fill-grey-100 {
  fill: #f4f4f6;
}

.fill-grey-300 {
  fill: #c3c4c6;
}

.fill-grey-400 {
  fill: #86888d;
}

.fill-primary-300 {
  fill: #ffd84c;
}

.fill-primary-600 {
  fill: #b37700;
}

.fill-white {
  fill: #fff;
}

.stroke-black {
  stroke: #000;
}

.stroke-current {
  stroke: currentColor;
}

.stroke-grey-400 {
  stroke: #86888d;
}

.stroke-primary-300 {
  stroke: #ffd84c;
}

.stroke-primary-600 {
  stroke: #b37700;
}

.stroke-1 {
  stroke-width: 1px;
}

.stroke-\[0\.5\] {
  stroke-width: .5px;
}

.\!p-0 {
  padding: 0 !important;
}

.\!p-4 {
  padding: 1rem !important;
}

.p-1\.5 {
  padding: .375rem;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1rem;
}

.p-6 {
  padding: 1.5rem;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-1\.5 {
  padding-left: .375rem;
  padding-right: .375rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-2\.5 {
  padding-left: .625rem;
  padding-right: .625rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-0\.5 {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-14 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-3\.5 {
  padding-top: .875rem;
  padding-bottom: .875rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pl-10 {
  padding-left: 2.5rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pr-6 {
  padding-right: 1.5rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-3 {
  padding-top: .75rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.font-serif {
  font-family: Capito, ui-serif, Georgia, Cambria, Times New Roman, Times, serif;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.italic {
  font-style: italic;
}

.leading-none {
  line-height: 1;
}

.leading-tight {
  line-height: 1.25;
}

.tracking-wide {
  letter-spacing: .025em;
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity, 1));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1));
}

.text-grey-400 {
  --tw-text-opacity: 1;
  color: hsl(223 3% 54% / var(--tw-text-opacity, 1));
}

.text-grey-600 {
  --tw-text-opacity: 1;
  color: hsl(220 8% 36% / var(--tw-text-opacity, 1));
}

.text-primary-600 {
  --tw-text-opacity: 1;
  color: hsl(40 100% 35% / var(--tw-text-opacity, 1));
}

.text-primary-900 {
  --tw-text-opacity: 1;
  color: hsl(40 70% 24% / var(--tw-text-opacity, 1));
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity, 1));
}

.text-transparent {
  color: #0000;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.text-yellow-800 {
  --tw-text-opacity: 1;
  color: rgb(133 77 14 / var(--tw-text-opacity, 1));
}

.underline-offset-2 {
  text-underline-offset: 2px;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.opacity-0 {
  opacity: 0;
}

.opacity-10 {
  opacity: .1;
}

.opacity-20 {
  opacity: .2;
}

.opacity-30 {
  opacity: .3;
}

.opacity-40 {
  opacity: .4;
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-none {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-black\/20 {
  --tw-shadow-color: #0003;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-black\/5 {
  --tw-shadow-color: #0000000d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-primary-400\/30 {
  --tw-shadow-color: #ebbc474d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-primary-500\/10 {
  --tw-shadow-color: #c996261a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-primary-500\/20 {
  --tw-shadow-color: #c9962633;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-primary-500\/40 {
  --tw-shadow-color: #c9962666;
  --tw-shadow: var(--tw-shadow-colored);
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-2 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-inset {
  --tw-ring-inset: inset;
}

.ring-black {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity, 1));
}

.ring-grey-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: hsl(240 10% 96% / var(--tw-ring-opacity, 1));
}

.ring-grey-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: hsl(240 29% 99% / var(--tw-ring-opacity, 1));
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.backdrop-blur-sm {
  --tw-backdrop-blur: blur(4px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-700 {
  transition-duration: .7s;
}

.ease-\[cubic-bezier\(\.34\,1\.56\,\.64\,1\)\] {
  transition-timing-function: cubic-bezier(.34, 1.56, .64, 1);
}

.\[grid-template-columns\:auto_1fr\] {
  grid-template-columns: auto 1fr;
}

.\[grid-template-columns\:auto_auto_auto_1fr\] {
  grid-template-columns: auto auto auto 1fr;
}

@media (width <= 1023px) {
  .disable-scrolling {
    touch-action: none;
    -webkit-overflow-scrolling: none;
    overscroll-behavior: none;
    overflow: hidden;
  }
}

.selection\:bg-primary-300 ::selection {
  --tw-bg-opacity: 1;
  background-color: hsl(47 100% 65% / var(--tw-bg-opacity, 1));
}

.selection\:bg-white ::selection {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.selection\:text-black ::selection {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity, 1));
}

.selection\:bg-primary-300::selection {
  --tw-bg-opacity: 1;
  background-color: hsl(47 100% 65% / var(--tw-bg-opacity, 1));
}

.selection\:bg-white::selection {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.selection\:text-black::selection {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity, 1));
}

.placeholder\:text-primary-600::placeholder {
  --tw-text-opacity: 1;
  color: hsl(40 100% 35% / var(--tw-text-opacity, 1));
}

.before\:absolute:before {
  content: var(--tw-content);
  position: absolute;
}

.before\:inset-x-0:before {
  content: var(--tw-content);
  left: 0;
  right: 0;
}

.before\:inset-y-1:before {
  content: var(--tw-content);
  top: .25rem;
  bottom: .25rem;
}

@keyframes pulse {
  50% {
    content: var(--tw-content);
    opacity: .5;
  }
}

.before\:animate-pulse:before {
  content: var(--tw-content);
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
}

.before\:rounded-full:before {
  content: var(--tw-content);
  border-radius: 9999px;
}

.before\:bg-black:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity, 1));
}

.after\:absolute:after {
  content: var(--tw-content);
  position: absolute;
}

.after\:inset-x-0:after {
  content: var(--tw-content);
  left: 0;
  right: 0;
}

.after\:bottom-full:after {
  content: var(--tw-content);
  bottom: 100%;
}

.after\:left-1\/2:after {
  content: var(--tw-content);
  left: 50%;
}

.after\:top-full:after {
  content: var(--tw-content);
  top: 100%;
}

.after\:block:after {
  content: var(--tw-content);
  display: block;
}

.after\:h-0:after {
  content: var(--tw-content);
  height: 0;
}

.after\:h-3:after {
  content: var(--tw-content);
  height: .75rem;
}

.after\:w-0:after {
  content: var(--tw-content);
  width: 0;
}

.after\:-translate-x-1\/2:after {
  content: var(--tw-content);
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.after\:bg-gradient-to-t:after {
  content: var(--tw-content);
  background-image: linear-gradient(to top, var(--tw-gradient-stops));
}

.after\:from-black\/5:after {
  content: var(--tw-content);
  --tw-gradient-from: #0000000d var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.after\:to-transparent:after {
  content: var(--tw-content);
  --tw-gradient-to: transparent var(--tw-gradient-to-position);
}

.after\:\[border-left\:8px_solid_transparent\]:after {
  content: var(--tw-content);
  border-left: 8px solid #0000;
}

.after\:\[border-right\:8px_solid_transparent\]:after {
  content: var(--tw-content);
  border-right: 8px solid #0000;
}

.after\:\[border-top\:8px_solid_rgba\(0\,0\,0\,\.8\)\]:after {
  content: var(--tw-content);
  border-top: 8px solid #000c;
}

.empty\:mb-0:empty {
  margin-bottom: 0;
}

.hover\:border-transparent:hover {
  border-color: #0000;
}

.hover\:bg-black:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity, 1));
}

.hover\:bg-gray-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity, 1));
}

.hover\:bg-primary-300:hover {
  --tw-bg-opacity: 1;
  background-color: hsl(47 100% 65% / var(--tw-bg-opacity, 1));
}

.hover\:bg-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.hover\:fill-black:hover {
  fill: #000;
}

.hover\:fill-white:hover {
  fill: #fff;
}

.hover\:text-black:hover {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity, 1));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.hover\:shadow-black\/20:hover {
  --tw-shadow-color: #0003;
  --tw-shadow: var(--tw-shadow-colored);
}

.hover\:shadow-black\/5:hover {
  --tw-shadow-color: #0000000d;
  --tw-shadow: var(--tw-shadow-colored);
}

.hover\:ring-0:hover {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.hover\:ring-2:hover {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.hover\:ring-inset:hover {
  --tw-ring-inset: inset;
}

.hover\:ring-black:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity, 1));
}

.focus\:bg-white:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-inset:focus {
  --tw-ring-inset: inset;
}

.focus\:ring-grey-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: hsl(220 12% 92% / var(--tw-ring-opacity, 1));
}

.focus\:placeholder\:text-grey-300:focus::placeholder {
  --tw-text-opacity: 1;
  color: hsl(220 3% 77% / var(--tw-text-opacity, 1));
}

.focus-visible\:rounded-full:focus-visible {
  border-radius: 9999px;
}

.focus-visible\:border-black:focus-visible {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity, 1));
}

.focus-visible\:bg-primary-300:focus-visible {
  --tw-bg-opacity: 1;
  background-color: hsl(47 100% 65% / var(--tw-bg-opacity, 1));
}

.focus-visible\:text-black:focus-visible {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity, 1));
}

.focus-visible\:outline-2:focus-visible {
  outline-width: 2px;
}

.focus-visible\:outline-4:focus-visible {
  outline-width: 4px;
}

.focus-visible\:outline-offset-4:focus-visible {
  outline-offset: 4px;
}

.focus-visible\:outline-primary-600:focus-visible {
  outline-color: #b37700;
}

.focus-visible\:ring-2:focus-visible {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-visible\:ring-4:focus-visible {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-visible\:ring-inset:focus-visible {
  --tw-ring-inset: inset;
}

.focus-visible\:ring-black:focus-visible {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity, 1));
}

.focus-visible\:ring-primary-300:focus-visible {
  --tw-ring-opacity: 1;
  --tw-ring-color: hsl(47 100% 65% / var(--tw-ring-opacity, 1));
}

.focus-visible\:ring-primary-600:focus-visible {
  --tw-ring-opacity: 1;
  --tw-ring-color: hsl(40 100% 35% / var(--tw-ring-opacity, 1));
}

.focus-visible\:ring-offset-4:focus-visible {
  --tw-ring-offset-width: 4px;
}

.focus-visible\:ring-offset-8:focus-visible {
  --tw-ring-offset-width: 8px;
}

.focus-visible\:selection\:bg-primary-300 :focus-visible::selection {
  --tw-bg-opacity: 1;
  background-color: hsl(47 100% 65% / var(--tw-bg-opacity, 1));
}

.focus-visible\:selection\:text-black :focus-visible::selection {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity, 1));
}

.focus-visible\:selection\:bg-primary-300:focus-visible::selection {
  --tw-bg-opacity: 1;
  background-color: hsl(47 100% 65% / var(--tw-bg-opacity, 1));
}

.focus-visible\:selection\:text-black:focus-visible::selection {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity, 1));
}

.disabled\:cursor-not-allowed:disabled {
  cursor: not-allowed;
}

.disabled\:bg-grey-200:disabled {
  --tw-bg-opacity: 1;
  background-color: hsl(220 12% 92% / var(--tw-bg-opacity, 1));
}

.disabled\:bg-primary-200:disabled {
  --tw-bg-opacity: 1;
  background-color: hsl(47 100% 82% / var(--tw-bg-opacity, 1));
}

.disabled\:bg-transparent:disabled {
  background-color: #0000;
}

.disabled\:text-\[\#C99727\]:disabled {
  --tw-text-opacity: 1;
  color: rgb(201 151 39 / var(--tw-text-opacity, 1));
}

.disabled\:text-grey-400:disabled {
  --tw-text-opacity: 1;
  color: hsl(223 3% 54% / var(--tw-text-opacity, 1));
}

.disabled\:text-primary-400:disabled {
  --tw-text-opacity: 1;
  color: hsl(43 80% 60% / var(--tw-text-opacity, 1));
}

.disabled\:shadow-none:disabled {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.disabled\:ring-2:disabled {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.disabled\:ring-\[\#C99727\]:disabled {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(201 151 39 / var(--tw-ring-opacity, 1));
}

.group[open] .group-open\:-rotate-90 {
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[open] .group-open\:rotate-90 {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group:invalid .group-invalid\:cursor-not-allowed {
  cursor: not-allowed;
}

.group:invalid .group-invalid\:bg-grey-200 {
  --tw-bg-opacity: 1;
  background-color: hsl(220 12% 92% / var(--tw-bg-opacity, 1));
}

.group:invalid .group-invalid\:bg-primary-200 {
  --tw-bg-opacity: 1;
  background-color: hsl(47 100% 82% / var(--tw-bg-opacity, 1));
}

.group:invalid .group-invalid\:bg-transparent {
  background-color: #0000;
}

.group:invalid .group-invalid\:text-\[\#C99727\] {
  --tw-text-opacity: 1;
  color: rgb(201 151 39 / var(--tw-text-opacity, 1));
}

.group:invalid .group-invalid\:text-grey-400 {
  --tw-text-opacity: 1;
  color: hsl(223 3% 54% / var(--tw-text-opacity, 1));
}

.group:invalid .group-invalid\:text-primary-400 {
  --tw-text-opacity: 1;
  color: hsl(43 80% 60% / var(--tw-text-opacity, 1));
}

.group:invalid .group-invalid\:shadow-none {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.group:invalid .group-invalid\:ring-2 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.group:invalid .group-invalid\:ring-\[\#C99727\] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(201 151 39 / var(--tw-ring-opacity, 1));
}

.group:hover .group-hover\:opacity-0 {
  opacity: 0;
}

.group:hover .group-hover\:opacity-60 {
  opacity: .6;
}

.group:focus-visible .group-focus-visible\:opacity-0 {
  opacity: 0;
}

.group:focus-visible .group-focus-visible\:opacity-60 {
  opacity: .6;
}

.peer:focus-visible ~ .peer-focus-visible\:ring {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.peer:focus-visible ~ .peer-focus-visible\:ring-primary-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: hsl(40 100% 35% / var(--tw-ring-opacity, 1));
}

.filled-invalid\:ring:invalid:not(:placeholder-shown) {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.filled-invalid\:ring-red-400:invalid:not(:placeholder-shown) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(248 113 113 / var(--tw-ring-opacity, 1));
}

@media not all and (width >= 1024px) {
  .max-lg\:hidden {
    display: none;
  }

  .max-lg\:overflow-hidden {
    overflow: hidden;
  }

  .max-lg\:truncate {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .max-lg\:overflow-ellipsis {
    text-overflow: ellipsis;
  }
}

@media not all and (width >= 480px) {
  .max-xs\:translate-y-px {
    --tw-translate-y: 1px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .max-xs\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .max-xs\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .max-xs\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }
}

@media (width >= 480px) {
  .xs\:absolute {
    position: absolute;
  }

  .xs\:top-full {
    top: 100%;
  }

  .xs\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .xs\:m-6 {
    margin: 1.5rem;
  }

  .xs\:mb-3 {
    margin-bottom: .75rem;
  }

  .xs\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .xs\:mt-1 {
    margin-top: .25rem;
  }

  .xs\:inline {
    display: inline;
  }

  .xs\:hidden {
    display: none;
  }

  .xs\:h-4 {
    height: 1rem;
  }

  .xs\:h-6 {
    height: 1.5rem;
  }

  .xs\:w-4 {
    width: 1rem;
  }

  .xs\:w-6 {
    width: 1.5rem;
  }

  .xs\:w-auto {
    width: auto;
  }

  .xs\:min-w-\[290px\] {
    min-width: 290px;
  }

  .xs\:flex-row {
    flex-direction: row;
  }

  .xs\:gap-2 {
    gap: .5rem;
  }

  .xs\:gap-3 {
    gap: .75rem;
  }

  .xs\:self-end {
    align-self: flex-end;
  }

  .xs\:justify-self-start {
    justify-self: start;
  }

  .xs\:justify-self-center {
    justify-self: center;
  }

  .xs\:p-5 {
    padding: 1.25rem;
  }

  .xs\:p-6 {
    padding: 1.5rem;
  }

  .xs\:px-3 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .xs\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .xs\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .xs\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .xs\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .xs\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

@media (width >= 640px) {
  .sm\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .sm\:col-start-2 {
    grid-column-start: 2;
  }

  .sm\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .sm\:mb-8 {
    margin-bottom: 2rem;
  }

  .sm\:mt-4 {
    margin-top: 1rem;
  }

  .sm\:h-12 {
    height: 3rem;
  }

  .sm\:h-3 {
    height: .75rem;
  }

  .sm\:h-4 {
    height: 1rem;
  }

  .sm\:h-6 {
    height: 1.5rem;
  }

  .sm\:h-\[500px\] {
    height: 500px;
  }

  .sm\:w-24 {
    width: 6rem;
  }

  .sm\:w-3 {
    width: .75rem;
  }

  .sm\:w-3\/5 {
    width: 60%;
  }

  .sm\:w-4 {
    width: 1rem;
  }

  .sm\:w-6 {
    width: 1.5rem;
  }

  .sm\:max-w-sm {
    max-width: 24rem;
  }

  .sm\:gap-4 {
    gap: 1rem;
  }

  .sm\:gap-6 {
    gap: 1.5rem;
  }

  .sm\:gap-x-3 {
    column-gap: .75rem;
  }

  .sm\:gap-x-4 {
    column-gap: 1rem;
  }

  .sm\:gap-x-6 {
    column-gap: 1.5rem;
  }

  .sm\:p-6 {
    padding: 1.5rem;
  }

  .sm\:p-8 {
    padding: 2rem;
  }

  .sm\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .sm\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .sm\:text-center {
    text-align: center;
  }

  .sm\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .sm\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .sm\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .sm\:leading-\[18px\] {
    line-height: 18px;
  }
}

@media (width >= 768px) {
  .md\:order-none {
    order: 0;
  }

  .md\:-my-0\.5 {
    margin-top: -.125rem;
    margin-bottom: -.125rem;
  }

  .md\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .md\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .md\:-ml-1 {
    margin-left: -.25rem;
  }

  .md\:-mr-0\.5 {
    margin-right: -.125rem;
  }

  .md\:mb-12 {
    margin-bottom: 3rem;
  }

  .md\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .md\:mr-6 {
    margin-right: 1.5rem;
  }

  .md\:mt-1 {
    margin-top: .25rem;
  }

  .md\:mt-32 {
    margin-top: 8rem;
  }

  .md\:grid {
    display: grid;
  }

  .md\:h-14 {
    height: 3.5rem;
  }

  .md\:h-5 {
    height: 1.25rem;
  }

  .md\:h-9 {
    height: 2.25rem;
  }

  .md\:w-32 {
    width: 8rem;
  }

  .md\:w-5 {
    width: 1.25rem;
  }

  .md\:translate-y-0 {
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:flex-row-reverse {
    flex-direction: row-reverse;
  }

  .md\:items-center {
    align-items: center;
  }

  .md\:justify-start {
    justify-content: flex-start;
  }

  .md\:justify-between {
    justify-content: space-between;
  }

  .md\:gap-16 {
    gap: 4rem;
  }

  .md\:gap-2 {
    gap: .5rem;
  }

  .md\:gap-4 {
    gap: 1rem;
  }

  .md\:gap-8 {
    gap: 2rem;
  }

  .md\:gap-x-20 {
    column-gap: 5rem;
  }

  .md\:gap-y-4 {
    row-gap: 1rem;
  }

  .md\:p-12 {
    padding: 3rem;
  }

  .md\:p-6 {
    padding: 1.5rem;
  }

  .md\:p-8 {
    padding: 2rem;
  }

  .md\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .md\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .md\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .md\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .md\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

@media (width >= 1024px) {
  .lg\:static {
    position: static;
  }

  .lg\:absolute {
    position: absolute;
  }

  .lg\:bottom-0 {
    bottom: 0;
  }

  .lg\:bottom-auto {
    bottom: auto;
  }

  .lg\:right-6 {
    right: 1.5rem;
  }

  .lg\:right-\[456px\] {
    right: 456px;
  }

  .lg\:top-6 {
    top: 1.5rem;
  }

  .lg\:z-0 {
    z-index: 0;
  }

  .lg\:m-0 {
    margin: 0;
  }

  .lg\:-my-1 {
    margin-top: -.25rem;
    margin-bottom: -.25rem;
  }

  .lg\:-my-1\.5 {
    margin-top: -.375rem;
    margin-bottom: -.375rem;
  }

  .lg\:my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .lg\:mb-10 {
    margin-bottom: 2.5rem;
  }

  .lg\:mb-14 {
    margin-bottom: 3.5rem;
  }

  .lg\:mb-2 {
    margin-bottom: .5rem;
  }

  .lg\:mb-20 {
    margin-bottom: 5rem;
  }

  .lg\:mb-3 {
    margin-bottom: .75rem;
  }

  .lg\:mb-4 {
    margin-bottom: 1rem;
  }

  .lg\:ml-1 {
    margin-left: .25rem;
  }

  .lg\:ml-2 {
    margin-left: .5rem;
  }

  .lg\:mr-6 {
    margin-right: 1.5rem;
  }

  .lg\:mt-16 {
    margin-top: 4rem;
  }

  .lg\:mt-2 {
    margin-top: .5rem;
  }

  .lg\:mt-32 {
    margin-top: 8rem;
  }

  .lg\:mt-40 {
    margin-top: 10rem;
  }

  .lg\:mt-8 {
    margin-top: 2rem;
  }

  .lg\:line-clamp-2 {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }

  .lg\:line-clamp-none {
    -webkit-line-clamp: none;
    -webkit-box-orient: horizontal;
    display: block;
    overflow: visible;
  }

  .lg\:block {
    display: block;
  }

  .lg\:inline-block {
    display: inline-block;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:h-16 {
    height: 4rem;
  }

  .lg\:max-h-\[90\%\] {
    max-height: 90%;
  }

  .lg\:w-5\/6 {
    width: 83.3333%;
  }

  .lg\:w-full {
    width: 100%;
  }

  .lg\:min-w-\[2\.5rem\] {
    min-width: 2.5rem;
  }

  .lg\:min-w-\[30rem\] {
    min-width: 30rem;
  }

  .lg\:max-w-\[376px\] {
    max-width: 376px;
  }

  .lg\:max-w-\[707px\] {
    max-width: 707px;
  }

  .lg\:max-w-lg {
    max-width: 32rem;
  }

  .lg\:flex-auto {
    flex: auto;
  }

  .lg\:translate-x-full {
    --tw-translate-x: 100%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:flex-row-reverse {
    flex-direction: row-reverse;
  }

  .lg\:items-center {
    align-items: center;
  }

  .lg\:justify-end {
    justify-content: flex-end;
  }

  .lg\:justify-center {
    justify-content: center;
  }

  .lg\:justify-between {
    justify-content: space-between;
  }

  .lg\:gap-14 {
    gap: 3.5rem;
  }

  .lg\:gap-2 {
    gap: .5rem;
  }

  .lg\:gap-3 {
    gap: .75rem;
  }

  .lg\:gap-5 {
    gap: 1.25rem;
  }

  .lg\:gap-6 {
    gap: 1.5rem;
  }

  .lg\:gap-x-4 {
    column-gap: 1rem;
  }

  .lg\:gap-y-4 {
    row-gap: 1rem;
  }

  .lg\:space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
  }

  .lg\:self-end {
    align-self: flex-end;
  }

  .lg\:overflow-hidden {
    overflow: hidden;
  }

  .lg\:truncate {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .lg\:rounded-2xl {
    border-radius: 1rem;
  }

  .lg\:rounded-none {
    border-radius: 0;
  }

  .lg\:rounded-xl {
    border-radius: .75rem;
  }

  .lg\:rounded-b-xl {
    border-bottom-right-radius: .75rem;
    border-bottom-left-radius: .75rem;
  }

  .lg\:rounded-t-xl {
    border-top-left-radius: .75rem;
    border-top-right-radius: .75rem;
  }

  .lg\:border-t {
    border-top-width: 1px;
  }

  .lg\:border-grey-200 {
    --tw-border-opacity: 1;
    border-color: hsl(220 12% 92% / var(--tw-border-opacity, 1));
  }

  .lg\:bg-primary-300\/80 {
    background-color: #ffd84ccc;
  }

  .lg\:bg-transparent {
    background-color: #0000;
  }

  .lg\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  }

  .lg\:p-0 {
    padding: 0;
  }

  .lg\:p-10 {
    padding: 2.5rem;
  }

  .lg\:p-14 {
    padding: 3.5rem;
  }

  .lg\:p-16 {
    padding: 4rem;
  }

  .lg\:p-6 {
    padding: 1.5rem;
  }

  .lg\:p-8 {
    padding: 2rem;
  }

  .lg\:px-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }

  .lg\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .lg\:px-3\.5 {
    padding-left: .875rem;
    padding-right: .875rem;
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:py-1 {
    padding-top: .25rem;
    padding-bottom: .25rem;
  }

  .lg\:py-1\.5 {
    padding-top: .375rem;
    padding-bottom: .375rem;
  }

  .lg\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .lg\:\!pb-0 {
    padding-bottom: 0 !important;
  }

  .lg\:pb-0 {
    padding-bottom: 0;
  }

  .lg\:pb-10 {
    padding-bottom: 2.5rem;
  }

  .lg\:pb-12 {
    padding-bottom: 3rem;
  }

  .lg\:pb-8 {
    padding-bottom: 2rem;
  }

  .lg\:pl-14 {
    padding-left: 3.5rem;
  }

  .lg\:pl-16 {
    padding-left: 4rem;
  }

  .lg\:pr-0 {
    padding-right: 0;
  }

  .lg\:pt-0 {
    padding-top: 0;
  }

  .lg\:pt-2 {
    padding-top: .5rem;
  }

  .lg\:pt-4 {
    padding-top: 1rem;
  }

  .lg\:pt-6 {
    padding-top: 1.5rem;
  }

  .lg\:pt-8 {
    padding-top: 2rem;
  }

  .lg\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .lg\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .lg\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .lg\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .lg\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .lg\:text-grey-400 {
    --tw-text-opacity: 1;
    color: hsl(223 3% 54% / var(--tw-text-opacity, 1));
  }

  .lg\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:backdrop-blur-sm {
    --tw-backdrop-blur: blur(4px);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }
}

@media (width >= 1280px) {
  .xl\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}

.\[\&\:\:-webkit-details-marker\]\:hidden::-webkit-details-marker {
  display: none;
}
/*# sourceMappingURL=index.9528cd79.css.map */
